import React, { FunctionComponent, ReactNode } from 'react';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import Head from 'next/head';

interface Props {
  children?: ReactNode;
  title: string;
}

const Layout: FunctionComponent<Props> = ({ children, title }) => {
  return (
    <div>
      <Head>
        <title>Agravla | {title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" key="viewport" />

        <meta
          name="description"
          content="Algemene grond &amp; afbraakwerken Vlayen is een bedrijf gevestigd in Destelbergen dat zich specialiseerd in grond-, (kleine)afbraak- en rubbouwwerken."
          key="description"
        />
        <meta property="og:title" content={title} key="ogTitle" />
        <meta
          property="og:description"
          content="Algemene grond &amp; afbraakwerken Vlayen is een bedrijf gevestigd in Destelbergen dat zich specialiseerd in grond-, (kleine)afbraak- en rubbouwwerken."
          key="ogDescription"
        />
        <meta property="og:site_name" content={`Agravla | ${title}`} key="ogSiteName" />
        <meta property="og:type" content="website" key="ogType" />

        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/images/favicon/site.webmanifest" />

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-T08PTBXWB8"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-T08PTBXWB8');`,
          }}
        />
      </Head>
      <Navigation />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
