import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';

const Navigation: FunctionComponent = () => {
  const router = useRouter();

  const handleActivePath = (pathname: string, slug?: string) => {
    if (router.pathname.includes(slug)) {
      return 'Navigation__Active';
    }
    return router.pathname === pathname ? 'Navigation__Active' : null;
  };

  return (
    <div className="Navigation_Container">
      <div className="Navigation_InnerContainer">
        <h1>
          <Link href="/">
            <a>
              <Image src="/images/logo.png" width="150" height="44" alt="Agravla" />
            </a>
          </Link>
        </h1>
        <nav>
          <ul>
            <li className={handleActivePath('/')}>
              <Link href="/">
                <a title="Home">Home</a>
              </Link>
            </li>
            <li className={handleActivePath('/activiteiten', '[slug]')}>
              <Link href="/activiteiten">
                <a title="Activiteiten">Activiteiten</a>
              </Link>
            </li>
            <li className={handleActivePath('/contact')}>
              <Link href="/contact">
                <a title="Contact">Contact</a>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
