import React, { FunctionComponent } from 'react';
import FacebookIcon from '../../assets/svg/facebook.svg';

const Footer: FunctionComponent = () => {
  return (
    <footer>
      <p>
        Agravla | <a href="tel:+32468419688">+32 468 41 96 88</a>
      </p>
      <p>
        <a href="mailto:jan.vlayen@agravla.be">jan.vlayen@agravla.be</a> | BE 0691.856.755
      </p>
      <p>
        <a target="_blank" href="https://www.facebook.com/Agravla" rel="noreferrer">
          <FacebookIcon />
        </a>
      </p>
    </footer>
  );
};

export default Footer;
